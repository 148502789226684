import React from "react";

export const Card = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full mb-8">
      <div className="flex w-48 h-48 mb-3 overflow-hidden bg-left-top bg-no-repeat bg-cover rounded-full shadow-md md:mb-6 bg-reeseandkarl" />
      <div className="mb-3 md:mb-0">
        <div className="mb-1 text-xl italic font-light font-hatton md:text-xl text-green">
          Reese and Karl's
        </div>
        <div className="mb-1 text-3xl italic font-bold tracking-wider uppercase font-hatton md:text-xl text-green">
          Wedding Registries
        </div>
      </div>
      <div className="text-sm font-light tracking-wide md:text-base font-hatton">
        We're over the moon for our day. <br /> Thank you all for your support!
      </div>
    </div>
  );
};

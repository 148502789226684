import React from "react";

export const VenmoIcon = () => {
  return (
    // <>
    //   <img
    //     className="w-[32px] h-[32px]"
    //     src="/images/venmo.png"
    //   />
    // </>

    <div className="flex w-[32px] h-[32px] md:w-[40px] md:h-[40px] overflow-hidden bg-center bg-no-repeat bg-cover rounded-full bg-venmo" />
  );
};

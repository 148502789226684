import { useEffect } from "react";
import "./App.css";
import { Card } from "./components/Card.tsx";
import { CardLink } from "./components/CardLink.tsx";
import ReactGA from 'react-ga4'

const TRACKING_ID = "G-289C4K2GT7";
function App() {
  
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Reese & Karl - Wedding Registry" });
  }, [])
  
  
  return (
    <div className="flex items-start justify-center w-screen min-h-screen pt-10 bg-bottom bg-no-repeat bg-cover md:pt-16 App bg-hillNoBlurMobile md:bg-hillNoBlur">
      <div className="flex w-[580px] flex-col justify-center items-center px-10">
        <Card />
        <CardLink
          icon="gift"
          label="Registry Website"
          link="https://www.zola.com/wedding/reeseandkarlson/registry"
          subtext="(Our main registry)"
        />
        <div className="flex my-2 text-sm text-black md:text-base font-hatton">
          or
        </div>
        <CardLink
          icon="venmo"
          label="Honeymoon Fund"
          link="venmo://paycharge?txn=pay&recipients=reeseandkarl-wedding"
          subtext="(Donate directly via Venmo)"
        />
      </div>
    </div>
  );
}

export default App;

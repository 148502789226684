import React from "react";
import { GiftIcon } from "./GiftIcon.tsx";
import { VenmoIcon } from "./VenmoIcon.tsx";
import ReactGA from "react-ga4";

export const CardLink = ({ label, link, icon, subtext }) => {
  const trackLinkClick = (label: string) => {
    ReactGA.event({
      category: "link",
      action: "click",
      label: label,
    });
  };
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex hover:border hover:border-1 hover:border-['#fafafa'] transition-all px-4 items-center justify-between flex-row w-full bg-white shadow-md rounded-full h-[64px]"
      onClick={() => trackLinkClick(label)}
    >
      <div className="">
        {icon == "gift" && <GiftIcon height={"32"} width={"32"} />}
        {icon == "venmo" && <VenmoIcon />}
      </div>
      <div className="flex flex-col items-center justify-center text-green">
        <div className="text-sm font-medium tracking-widest text-black uppercase md:text-base font-hatton">
          {label}
        </div>
        <div className="text-sm md:text-sm text-[#babab5]">{subtext}</div>
      </div>
      <div className="w-[32px]"></div>
    </a>
  );
};
